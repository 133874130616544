import { Component, OnInit } from '@angular/core';
import {LoginService } from '../../../login.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private login_service: LoginService) { }

  	footer_logo=null
  ngOnInit() {
  	this.login_service.get('v1/api/get-logo-data').subscribe(res => {
        this.footer_logo=res['data'][0].footer_logo
            document.getElementById('appFavicon').setAttribute('href', res['data'][0].favicon_logo);

        })
  }

}
