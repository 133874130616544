import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mangorobo';
  show=0;
  ngOnInit(){

  	setTimeout(()=>{ 
  		this.show=1
  	}, 1000);

  }
}
