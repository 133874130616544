import { Component, OnInit } from '@angular/core';
import {LoginService } from '../../../login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private login_service: LoginService) { }

  	header_logo=null
  ngOnInit() {
  	this.login_service.get('v1/api/get-logo-data').subscribe(res => {
        this.header_logo=res['data'][0].header_logo
        })
  }
clickClass(){
	// alert('dev')
	document.getElementsByClassName('menu-drawer')[0].setAttribute('style','display:block;')
	// document.getElementsByClassName('menu-drawer')[0].classList.toggle('displayN')
}
}
