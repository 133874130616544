import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    {
        path: 'home',
        loadChildren: './component/mainpage/mainpage.module#MainpageModule',
    },
    {
        path: 'about-us',
        loadChildren: './component/aboutus/aboutus.module#AboutusModule',
    }, {
        path: 'blog',
        loadChildren: './component/blog/blog.module#BlogModule',
    }, {
        path: 'blog-detail/:id',
        loadChildren: './component/blog-detail/blog-detail.module#BlogDetailModule',
    },
    {
        path: 'contact-us',
        loadChildren: './component/contactus/contactus.module#ContactusModule',
    },
    //  {
    //  path        : 'what-we-do',
    //     loadChildren: './component/whatwedo/whatwedo.module#WhatwedoModule',
    // }, 
    {
        path: 'smartskills',
        loadChildren: './component/smartskills/smartskills.module#SmartskillsModule',
    },
    {
        path: 'our-process',
        loadChildren: './component/our-process/our-process.module#OurProcessModule',
    },
    {
        path: '**',
        redirectTo: 'home',
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
